import React from "react";

function IconZilPayLine(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.4 59.3" className={props.className} width={props.width} height={props.height}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" d="M27.4,58.4c-9.4-.9-17.9-5.9-22.5-13a28,28,0,0,1-3.8-9.7,59.2,59.2,0,0,1-.3-6.3c.3-6.6,1.8-11.7,4.9-16.2A23,23,0,0,1,9,9.4a28.9,28.9,0,0,1,8.4-5.7A28.8,28.8,0,0,1,26.2,1a43.3,43.3,0,0,1,7.2,0A29.1,29.1,0,0,1,48.5,8.4c5,4.4,8.3,9.5,9.5,14.8a20.1,20.1,0,0,1,.5,5.1,18.7,18.7,0,0,1-.5,5.4l-.3,1.4-1,.2a62.4,62.4,0,0,0-8.8,2.3l-4.7,2a40.5,40.5,0,0,1-4.9,1.9,14.8,14.8,0,0,1-7.8.1,7.4,7.4,0,0,1-5.2-5.5,7.5,7.5,0,0,1,.2-3.3,16.1,16.1,0,0,1,3.8-6.4l.7-.8s.1.9.3,2,.2,2,.2,2l.8-.3a13.6,13.6,0,0,1,7.5-.4,48.7,48.7,0,0,1,5.8,1.8l2.1.7a10.8,10.8,0,0,0,5.6-.7c.5-.2.5-.2.6-.7a7.6,7.6,0,0,0,0-3v-.6l-1.7-1.2C40.4,18.1,31.5,14,23.9,12.5c-2-.4-5.6-.8-5.6-.6v.2c-.1.1,1,.7,3.7,2l3.8,1.8-1.6.6a57.1,57.1,0,0,0-6,2.4A22.4,22.4,0,0,0,14.6,21l1.8.2,1.7.2-1,1c-1.2,1.3-2,2.2-3.8,4.5l-1.6,1.9h2.7l-1.3,4.6c-.8,2.5-1.4,4.6-1.4,4.6l1.4-.3c1-.2,1.5-.3,1.5-.2s.2,2.1.4,4.5.3,4.5.3,4.5l1.3-1a8.9,8.9,0,0,1,1.2-1,34.8,34.8,0,0,1,2.5,3.6l2.5,3.7L24,50.6c.8-1,1.1-1.2,1.2-1.1l1,1.2a18,18,0,0,0,1.9,2.1l1,1h2.2a21.4,21.4,0,0,1,8.5,1.4l.8.3-.3.2a15.2,15.2,0,0,1-6.7,2.6l-3.3.2Z"/>
                </g>
            </g>
        </svg>
    );
}

export default IconZilPayLine;